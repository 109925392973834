import { createReducer } from '../../../shared/reducers/createReducer';
import { Action, AsyncAction } from '../../../shared/services/ActionHelpers';

export const containerId = 'Documents';
export const SigningMethodFormId = 'SigningMethodForm';
export const IdentityConfirmationFormId = 'IdentityConfirmation';

export const fetchData = new AsyncAction(`${containerId}/DATA_FETCH`);
export const fetchIdentity = new AsyncAction(`${containerId}/IDENTITY_FETCH`);
export const uploadFirstSideOfIdCard = new AsyncAction(`${containerId}/FIRST_SIDE_ID_CARD_UPLOAD`);
export const uploadSecondSideOfIdCard = new AsyncAction(
  `${containerId}/SECOND_SIDE_ID_CARD_UPLOAD`
);
export const uploadIdCard = new Action(`${containerId}/ID_CARD_UPLOAD`);
export const confirmIdentity = new AsyncAction(`${containerId}/IDENTITY_CONFIRM`);
export const pollDocuments = new AsyncAction(`${containerId}/DOCUMENTS_POLL`);
export const setPollingMessageId = new Action(
  `${containerId}/POLLING_MESSAGE_ID_SET`,
  'documents.mightTakeTime'
);
export const setProposalStatus = new Action(`${containerId}/PROPOSAL_STATUS_SET`);
export const fetchSigningMethod = new AsyncAction(`${containerId}/SIGNING_METHOD_FETCH`);
export const submitSigningMethod = new AsyncAction(`${containerId}/SIGNING_METHOD_SUBMIT`);
export const setSigners = new Action(`${containerId}/SIGNERS_SET`);
export const requestSigningUrl = new AsyncAction(`${containerId}/SIGNING_URL_REQUEST`);
export const saveSigningMode = new AsyncAction(`${containerId}/SIGNING_MODE_SAVE`);
export const sendEmailForDownload = new AsyncAction(`${containerId}/EMAIL_FOR_DOWNLOAD_SEND`);
export const downloadDocument = new AsyncAction(`${containerId}/DOCUMENT_DOWNLOAD`);
export const clearDocuments = new Action(`${containerId}/DOCUMENTS_CLEAR`);
export const setSigningError = new Action(`${containerId}/SIGNING_ERROR_SET`, false);
export const setActiveSigner = new Action(`${containerId}/ACTIVE_SIGNER_SET`);
export const setSavingSigningMode = new Action(`${containerId}/SAVING_SIGNING_MODE_SET`, {});
export const setExternalReference = new Action(`${containerId}/EXTERNAL_REFERENCE_SET`);

const actionToHandlerMap = new Map([
  [
    setSavingSigningMode.ACTION,
    (draft, { payload: policyPartyType }) => {
      draft[setSavingSigningMode.type][policyPartyType] = true;
    },
  ],
  [
    setSavingSigningMode.CLEAR,
    (draft, { payload: policyPartyType }) => {
      if (policyPartyType) {
        delete draft[setSavingSigningMode.type][policyPartyType];
      } else {
        draft[setSavingSigningMode.type] = setSavingSigningMode.initialState;
      }
    },
  ],
]);

export const documentsActions = [
  fetchData,
  fetchIdentity,
  uploadFirstSideOfIdCard,
  uploadSecondSideOfIdCard,
  confirmIdentity,
  pollDocuments,
  setPollingMessageId,
  setProposalStatus,
  fetchSigningMethod,
  submitSigningMethod,
  saveSigningMode,
  setSigners,
  requestSigningUrl,
  setSigningError,
  setActiveSigner,
  setSavingSigningMode,
  sendEmailForDownload,
  setExternalReference,
];

export default createReducer(documentsActions, null, actionToHandlerMap);
